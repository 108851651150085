import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import user from '../images/user.svg'
import siteImage from '../images/pexels-dominika-roseclay-4083654.jpg'
import siteImage2 from '../images/pexels-dominika-roseclay-4148018.jpg'
import siteImage3 from '../images/pexels-cottonbro-4917811.jpg'
import siteImage4 from '../images/pexels-anna-shvets-5760788.jpg'




function IndexPage() {
  return (
    <Layout>
      <SEO
        title="REG Digital Media"
        description="We Build Websites that Focus on User Experience"
      />

<section className="text-center ">

  <div className="my-20 max-w-4xl px-4 mx-auto">
  
<p className="my-4">Our goal is to help people by providing excelent quality content to anwser all their questions. But we understand that quality content is just a part of the overall user experience.</p>
<p className="my-4">That&apos;s why our websites are lighting fast and deliver skimmable content with lots of quality images.</p>
<p className="my-4">We specialize in SEO as our main source of top tier traffic.</p>
</div>

  <h2 className="text-4xl my-10 font-bold">Our Verticals:</h2>

  <div className="flex flex-col">

  <div className=" bg-blue-50 bg-opacity-50">

    <div className="md:grid grid-cols-2 my-8 items-center max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
    <img
          alt="Cat and human sitting on a couch"
          className="block w-1/2 mx-auto"
          src={siteImage}
        />

        <div className=" max-w-lg mx-auto px-6">
        <h3 className="mb-6">Home Decor</h3> 

        <p className="text-gray-800 text-lg">A website that inspires you to create warm, inviting homes.</p>

        <div className="my-6 flex justify-between">

        <div className="my-4 flex flex-col align-baseline">
        
        <div className="flex">
        <img src={user} alt="logo" /><span className="text-blue-700 font-bold mx-4">+300.000</span>
        </div>

        <div className="flex text-sm text-gray-500">Monthly UV</div>
        
        
        </div>

        <div className="my-4 flex flex-col align-baseline">
        
        <div className="flex">
        <img src={user} alt="logo" /><span className="text-red-600 font-bold mx-4">+100.000</span>
        </div>

        <div className="flex text-sm text-gray-500">Social Media Followers</div>
        
        </div>
        </div>

        </div>
        
        </div>
    </div>
    <div>

<div className="md:grid grid-cols-2 my-8 items-center max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
    <img
          alt="Cat and human sitting on a couch"
          className="block w-1/2 mx-auto order-2"
          src={siteImage2}
        />

        <div className=" max-w-lg mx-auto px-6">
        <h3 className="mb-6">Home Decor & DIY</h3> 

        <p className="text-gray-800 text-lg">A website that focus on DIY projects and affordable home decorating. </p>

        <div className="my-6 flex justify-between">

        <div className="my-4 flex flex-col align-baseline">
        
        <div className="flex">
        <img src={user} alt="logo" /><span className="text-blue-700 font-bold mx-4">+75.000</span>
        </div>

        <div className="flex text-sm text-gray-500">Monthly UV</div>
        
        
        </div>

        <div className="my-4 flex flex-col align-baseline">
        
        <div className="flex">
        <img src={user} alt="logo" /><span className="text-red-600 font-bold mx-4">+70.000</span>
        </div>

        <div className="flex text-sm text-gray-500">Social Media Followers</div>
        
        </div>
        </div>

        </div>
        </div>
        
        <div className=" bg-blue-50 bg-opacity-50">

<div className="md:grid grid-cols-2 my-8 items-center max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
<img
      alt="Cat and human sitting on a couch"
      className="block w-1/2 mx-auto"
      src={siteImage3}
    />

    <div className=" max-w-lg mx-auto px-6">
    <h3 className="mb-6">Homesteading</h3> 

    <p className="text-gray-800 text-lg">A website that is all about homesteading, with lots of information and useful tips.</p>

    <div className="my-6 flex justify-between">

    <div className="my-4 flex flex-col align-baseline">
    
    <div className="flex">
    <img src={user} alt="logo" /><span className="text-blue-700 font-bold mx-4">+7.000</span>
    </div>

    <div className="flex text-sm text-gray-500">Monthly UV</div>
    
    
    </div>

    <div className="my-4 flex flex-col align-baseline">
    
    <div className="flex">
    <img src={user} alt="logo" /><span className="text-red-600 font-bold mx-4">+50</span>
    </div>

    <div className="flex text-sm text-gray-500">Social Media Followers</div>
    
    </div>
    </div>

    </div>
    
    </div>
</div>

<div className="md:grid grid-cols-2 my-8 items-center max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
    <img
          alt="Cat and human sitting on a couch"
          className="block w-1/2 mx-auto order-2"
          src={siteImage4}
        />

        <div className=" max-w-lg mx-auto px-6">
        <h3 className="mb-6">DIY & Crafts</h3> 

        <p className="text-gray-800 text-lg">A website that will inspire you with lots of easy DIY projects.</p>

        <div className="my-6 flex justify-between">

        <div className="my-4 flex flex-col align-baseline">
        
        <div className="flex">
        <img src={user} alt="logo" /><span className="text-blue-700 font-bold mx-4">+17.000</span>
        </div>

        <div className="flex text-sm text-gray-500">Monthly UV</div>
        
        
        </div>

        <div className="my-4 flex flex-col align-baseline">
        
        <div className="flex">
        <img src={user} alt="logo" /><span className="text-red-600 font-bold mx-4">+3.900</span>
        </div>

        <div className="flex text-sm text-gray-500">Social Media Followers</div>
        
        </div>
        </div>

        </div>
        </div>

    </div>

  </div>

</section>

<section className="text-center py-20 bg-blue-50">
  
  <h2 id="contact" className="text-4xl mb-10 font-bold">Contact Us</h2>

  <p>For advertising inquiries please contact us at: advertising@regdigitalmedia.com</p>

  </section>



    </Layout>
  );
}

export default IndexPage;
